<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="600px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="180px"
      >
        <el-form-item label="文件名称" prop="fileName">
          <el-input
            v-model="airForm.fileName"
            placeholder="请输入文件名称"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="文件编号" prop="fileNo">
          <el-input
            v-model="airForm.fileNo"
            placeholder="请输入文件编号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="版本状态" prop="versionStatus">
          <el-input
            v-model="airForm.versionStatus"
            placeholder="请输入版本状态"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="生效日期" prop="effectiveDate">
          <el-date-picker
            v-model="airForm.effectiveDate"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="文件上传" prop="url">
          <el-upload
            ref="uploadRef"
            :disabled="handleType !== 'add' && handleType !== 'update'"
            action=""
            :http-request="handleHttp"
            :on-remove="handleRemove"
            :file-list="fileList"
          >
            <el-button size="small" type="primary"> 点击上传 </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="airForm.remarks"
            placeholder="请输入备注"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="文件名称">
        <el-input
          v-model="searchForm.fileName"
          placeholder="请输入文件名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="fileName" label="文件名称" />
      <el-table-column prop="fileNo" label="文件编号" />
      <el-table-column prop="urlName" label="文件上传url名称" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getControlledList,
  deleteControlledList,
  addControlledList,
  getControlledDetail,
  updateControlledDetail,
} from "@/api/humanManagement/humanManagement.js";
import { upload, download } from "@/api/main/file";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      waterList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules2: {
        fileName: [
          {
            required: true,
            message: "文件名称不能为空",
            trigger: ["change", "blur"],
          },
        ],
        fileNo: [
          {
            required: true,
            message: "文件编号不能为空",
            trigger: ["change", "blur"],
          },
        ],
        versionStatus: [
          {
            required: true,
            message: "版本状态不能为空",
            trigger: ["change", "blur"],
          },
        ],
        effectiveDate: [
          {
            required: true,
            message: "生效日期不能为空",
            trigger: ["change", "blur"],
          },
        ],
        url: [
          {
            required: true,
            message: "文件上传不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      fileList: [],
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let waterList = await getControlledList(params);
    this.waterList = waterList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addControlledList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            await updateControlledDetail(params);
          }
          this.waterList = await getControlledList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.airId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            let data = await addControlledList(this.airDetailForm);
            let list = this.airForm.airDetailList;
            list.push(data);
            this.airForm.airDetailList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.airDetailList;
            console.log(this.airDetailForm, "this.airDetailForm");
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            list.splice(this.airDetailForm.index, 1, this.airDetailForm);
            this.airForm.airDetailList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增文件信息";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteControlledList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.waterList = await getControlledList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "受控文件清单";
      this.handleType = "update";
      getControlledDetail(row.id).then((res) => {
        this.airForm = { ...res };
        this.fileList = [{
          name: res.urlName,
          path: res.url,
        }]
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "受控文件清单";
      this.handleType = "detail";
      getControlledDetail(row.id).then((res) => {
        this.airForm = { ...res };
        console.log({ ...res });
        this.fileList = [{
          name: res.urlName,
          path: res.url,
        }]
        // this.runningTime = [res.startupTime || "", res.offTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getControlledList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.waterList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getControlledList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getControlledList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    handleHttp(file) {
      const data = new FormData();
      data.append("file", file.file);
      upload(data).then((res) => {
        console.log(res, "res");
        console.log(file, "file");
        this.airForm.url = res;
        this.fileList = [{
          name: file.file.name,
          path: res,
        }]
        this.airForm.urlName = file.file.name;
      });
    },
    handleRemove() {
      this.airForm.url = "";
      this.airForm.urlName = "";
    },
  },

  watch: {
    runningTime(val, oldVal) {
      console.log(val, "val");
      this.airForm.entryExitDate = val.join("-");
    },
    airDialogVisible(val) {
      if (!val) {
        this.airForm = {};
        this.runningTime = [];
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
